var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑租户"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"租户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"登陆账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['emp_no', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入登录账号' },
              { pattern: /^[a-zA-Z0-9_]{4,100}$/, message: '只能由4-100位 字母、数字 或 下划线 组成' } ]
          }]),expression:"['emp_no', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入登录账号' },\n              { pattern: /^[a-zA-Z0-9_]{4,100}$/, message: '只能由4-100位 字母、数字 或 下划线 组成' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"登录密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
            rules: [
              { message: '请输入登录密码' },
              { min: 6, message: '最少6个字符' },
              { max: 100, message: '最多100个字符' },
              { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
            ]
          }]),expression:"['password', {\n            rules: [\n              { message: '请输入登录密码' },\n              { min: 6, message: '最少6个字符' },\n              { max: 100, message: '最多100个字符' },\n              { pattern: /^\\S.*\\S$/, message: '首尾不能为空格' }\n            ]\n          }]"}],attrs:{"autocomplete":"new-password"}})],1),_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contact', {
            normalize: this.$lodash.trim,
            rules: [
              { max: 100, message: '最多100个字符' },
              { required: true, message: '请输入联系人' }
            ]
          }]),expression:"['contact', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { max: 100, message: '最多100个字符' },\n              { required: true, message: '请输入联系人' }\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            normalize: this.$lodash.trim,
            rules: [
              { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' },
              { required: true, message: '请输入联系电话' }
            ]
          }]),expression:"['phone_number', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { pattern: /^1[3-9]\\d{9}$/, message: '手机号格式不正确' },\n              { required: true, message: '请输入联系电话' }\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }