<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑租户"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="租户名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="登陆账号">
          <a-input
            v-decorator="['emp_no', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入登录账号' },
                { pattern: /^[a-zA-Z0-9_]{4,100}$/, message: '只能由4-100位 字母、数字 或 下划线 组成' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="登录密码">
          <a-input-password
            autocomplete="new-password"
            v-decorator="['password', {
              rules: [
                { message: '请输入登录密码' },
                { min: 6, message: '最少6个字符' },
                { max: 100, message: '最多100个字符' },
                { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="联系人">
          <a-input
            v-decorator="['contact', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 100, message: '最多100个字符' },
                { required: true, message: '请输入联系人' }
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            v-decorator="['phone_number', {
              normalize: this.$lodash.trim,
              rules: [
                { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' },
                { required: true, message: '请输入联系电话' }
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <!--        <a-form-item label="APP ID">-->
        <!--          <a-input-->
        <!--            v-decorator="['app_id', {-->
        <!--              normalize: this.$lodash.trim-->
        <!--            }]"-->
        <!--          />-->
        <!--        </a-form-item>-->
        <!--        <a-form-item label="APP SECRET">-->
        <!--          <a-input-->
        <!--            v-decorator="['app_secret', {-->
        <!--              normalize: this.$lodash.trim-->
        <!--            }]"-->
        <!--          />-->
        <!--        </a-form-item>-->
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateTenantAdmin } from '@/api/tenant'
import { verificationPhoneNumber } from '@/api/user'

export default {
  name: 'EditTenantAdmin',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'user' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.initFormData()
  },
  methods: {
    initFormData() {
      this.data = Object.assign({}, { name: this.record.name, emp_no: this.record.emp_no, password: '', contact: this.record.contact, phone_number: this.record.phone_number })
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      const vm = this
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          verificationPhoneNumber({ phone_number: values.phone_number, id: this.record.id }).then(res => {
            if (res.code === 30003) {
              this.submitting = false
              this.$confirm({
                title: '手机号已存在',
                content: '是否继续创建',
                okText: '继续',
                cancelText: '取消',
                onOk() {
                  updateTenantAdmin(vm.record.id, values).then((res) => {
                    if (res.code === 0) {
                      // 关闭模态框
                      vm.isShow = false
                      // 告知父组件已完成
                      vm.$emit('completed')
                    }
                    vm.submitting = false
                  })
                },
                onCancel() {
                  vm.submitting = true
                }
              })
            }
            if (res.code === 0) {
              updateTenantAdmin(this.record.id, values).then((res) => {
                if (res.code === 0) {
                  // 关闭模态框
                  vm.isShow = false
                  // 告知父组件已完成
                  vm.$emit('completed')
                }
                vm.submitting = false
              })
            }
          })
        }
      })
    }
  }
}
</script>
